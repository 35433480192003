import { SimpleGrid } from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';

import { AssetImage } from '~/components/asset-image';
import { StandardLayout } from '~/components/layout';
import { RichText } from '~/components/rich-text';
import { isRichText } from '~/utilities/rich-text.utility';

const ProjectPage = ({ data }: PageProps<Queries.ProjectPageQuery>) => {
  const project = data.contentfulProject;

  if (!project) {
    return <></>;
  }

  return (
    <StandardLayout invertColors>
      <SimpleGrid
        columns={[1, null, 2]}
        spacing="4"
        height="100%"
        alignItems="center">
        {isRichText(project.description) ? (
          <RichText content={project.description} />
        ) : (
          <></>
        )}
        {project.heroImage && (
          <AssetImage
            image={project.heroImage}
            aspectRatio={9 / 16}
            objectFit="contain"
          />
        )}
      </SimpleGrid>
    </StandardLayout>
  );
};

export default ProjectPage;

export const query = graphql`
  query ProjectPage($projectEntryId: String) {
    contentfulProject(id: { eq: $projectEntryId }) {
      name
      description {
        raw
      }
      heroImage {
        title
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
